import { Input, Dropdown, DateSelect } from "../../../common";
import { CreateFile } from "../CreateFile";

export function SafetyNets({ values, staff, handleChange, handleBlur, setFieldValue, setFieldTouched }) {
  const renderStaffList = () => {
    if (staff && staff?.length > 0) {
      return staff.map((item) => ({
        label: item.staff_name,
        value: item.id,
      }));
    }
    return [];
  };
  return (
    <div>
      <h3 className="px-4 pt-2 text-lg font-semibold leading-5">Safety Nets</h3>
      <div>
        <div className="flex items-center">
          <DateSelect
            title="Issue Date"
            id="safety_nets_issue_date"
            value={values.safety_nets_issue_date}
            onChange={setFieldValue}
          />
          <DateSelect
            title="Expiry Date"
            id="safety_nets_expiry_date"
            value={values.safety_nets_expiry_date}
            onChange={setFieldValue}
          />
        </div>
        <div>
          <h4 className="text-md font-normal leading-5 px-4">Photo</h4>
          <CreateFile field="safety_nets_photo" setFieldValue={setFieldValue} type="edit" />
        </div>
        <div>
          <h4 className="text-md font-normal leading-5 px-4">Photo 2</h4>
          <CreateFile field="safety_nets_photo_2" setFieldValue={setFieldValue} type="edit" />
        </div>
        <div className="w-1/2">
          <Dropdown
            label="Safety Nets Assessed By"
            id="safety_nets_assessed_by"
            value={values.safety_nets_assessed_by}
            onChangeVal="label"
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            options={renderStaffList()}
          />
        </div>
      </div>
    </div>
  );
}
