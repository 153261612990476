import React, { useState, useEffect } from "react";
import { PDFViewer, pdf, blobPdf } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import supabase from "../../../api/supabase";
import { Quote } from "./Quote";
import { fetchQuote } from "../../../api/Quotes";

import { Spinner } from "../../../common";

import { Output } from "./Output";
import { DownloadPDF } from "./DownloadPDF";

import { useQuoteStore } from "./store";

export function QuotePdf({ quoteIdInternal }) {
  const [quote, setQuote] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { quoteId } = useParams(0);

  const { addQuote, setLoading } = useQuoteStore();

  useEffect(async () => {
    if (quoteId || quoteIdInternal) {
      const quoteData = await fetchQuote(quoteId || quoteIdInternal);

      let totalErectDismantle = 0;
      let WeeklyHireFees = 0;
      let totalAddOns = 0;
      for (const row of quoteData.quote_lines) {
        totalErectDismantle += Number(row.erect_dismantle || 0)
        WeeklyHireFees += Number(row.weekly_fee || 0);
      }
      if (quoteData?.quote_addons?.length) {
        for (const row of quoteData.quote_addons) {
          totalAddOns += Number(row.total || 0);
        }
      }

      quoteData.summaryCosts = [
        {
          "id": 1,
          "summaryConcept": "Total Weekly Amount",
          "summaryTotalCost": WeeklyHireFees
        },
        {
          "id": 2,
          "summaryConcept": "Total Erect and Dismantle",
          "summaryTotalCost": totalErectDismantle
        },
        {
          "id": 3,
          "summaryConcept": "Total Additions",
          "summaryTotalCost": totalAddOns
        }
      ]

      // setQuote(quoteData);
      setIsLoading(false);
      addQuote(quoteData);
      setLoading(false);
    }
  }, [quoteId, quoteIdInternal]);

  if (isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <Output quote={quote} />
  );
}