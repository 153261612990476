import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

export const deleteFile = async ({ fileId }) => {

  const { data, error } = await supabase
    .from("files")
    .delete()
    .match({ id: Number(fileId) });
  

    if (data?.[0]?.job_id !== null && data?.[0]?.job_id !== undefined) {

      const jobPhotosPayload = [];
  
      data.map((jobPhoto) =>
        jobPhotosPayload.push([
          jobPhoto.id,
          jobPhoto.job_id,
          jobPhoto.file,
          jobPhoto.file_description,
          jobPhoto.file_type,
          jobPhoto.notes,
          'true',
        ]),
      );
  
      await axios.post("https://frontier-server-scaffm8.herokuapp.com/api/data-sync", {
        id: "eb106438-f633-4a94-9e67-b1a00018c349",
        data: jobPhotosPayload,
      });
    }

  if (error) {
    throw new Error(error.message);
  }
  return data;
};

export const useDeleteFile = ({ fileId }) => {
  const queryClient = useQueryClient();

  return useMutation({
    onMutate: async (deletedFile) => {
      await queryClient.cancelQueries(["files", fileId]);

      const previousFiles = queryClient.getQueryData(["files", fileId]);

      queryClient.setQueryData(
        ["files", fileId],
        previousFiles?.filter((asset) => asset.id !== deletedFile?.fileId),
      );

      return { previousFiles };
    },
    onError: (_, __, context) => {
      if (context?.previousFiles) {
        queryClient.setQueryData(["files", fileId], context.previousFiles);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["files", fileId]);
    },
    mutationFn: deleteFile,
  });
};
