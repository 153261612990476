import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchActiveClients() {
  const { data, error } = await supabase.from("clients").select("*").match({ status: "Active" });
  if (error) {
    throw new Error(error.message);
  }
  console.log(data.length)
  console.log(".............")
  return data;
}
// export const useActiveClients = () => useQuery({
//   queryKey: ["Activeclients"],
//   queryFn: () => fetchActiveClients(),
// });
export const useActiveClients = () => {
  // const query = useQuery({
  //   queryKey: ['Activeclients'],
  //   queryFn: () => fetchActiveClients(),
  // });
  const query = useQuery('Activeclients', fetchActiveClients);

  const refetchActiveClients = () => {
    query.refetch();
  };
  return {
    ...query,
    refetchActiveClients,
  };
};
