import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

async function updateFile({ file, fileId }) {
  const { data, error } = await supabase.from("files").update(file).match({ id: fileId }).select("*");

  console.log("data", data);
  if (data?.[0]?.job_id !== null && data?.[0]?.job_id !== undefined) {

    const jobPhotosPayload = [];

    data.map((jobPhoto) =>
      jobPhotosPayload.push([
        jobPhoto.id,
        jobPhoto.job_id,
        jobPhoto.file,
        jobPhoto.file_description,
        jobPhoto.file_type,
        jobPhoto.notes,
        'false',
      ]),
    );

    await axios.post("https://frontier-server-scaffm8.herokuapp.com/api/data-sync", {
      id: "eb106438-f633-4a94-9e67-b1a00018c349",
      data: jobPhotosPayload,
    });
  }

  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useUpdateFile() {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((payload) => updateFile(payload), {
    onSuccess: () => {
      queryClient.refetchQueries("files");
      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully updated file.`,
      });
    },
    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed to update file",
        content: err?.message,
      });
    },
    mutationFn: updateFile,
  });
}
